.mainContainer {
  max-width: 1400px;
  margin: auto;
  margin-bottom: 120px;
  background: linear-gradient(to bottom, #f7f7f7 50%, white 50%);
}

@media screen and (max-width: 600px) {
  .mainContainer {
    width: 100%;
  }
}
