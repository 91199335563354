.containerMain {
  width: 100%;
  padding-left: 60px;
  margin-top: 80px;
}

.divTable {
  max-width: 400px;
  padding: 20px 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
}

/* .divForm {
  position: relative;
  margin-top: 80px;
} */

.divForm h3 {
  color: var(--c11);
  font-family: var(--type-first);
  font-size: 36px;
  line-height: 40px;
  font-weight: 800;
  margin-bottom: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 60px 20px 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 200px;
}

.form button {
  font-family: var(--type-second);
  margin-top: 12px;
  margin-bottom: 32px;
  font-weight: 500;
  align-self: center;
  width: fit-content;
  font-size: 18px;
  line-height: 24px;
  color: #f7f7f7;
  padding: 16px 32px;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  background: linear-gradient(#41bd9a, #47b87a);
}

.form button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s;
}

.sep {
  display: inline-block;
  background: var(--c3);
  width: calc(100% + 40px);
  height: 2px;
  border-radius: 4px;
  margin-left: -20px;
  margin-bottom: 7px;
}

.error {
  color: red;
  align-self: center;
  font-family: var(--type-second);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 20px;
}

.desInvalido {
  color: var(--c8);
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.msgCopiada {
  color: green;
  text-align: center;
  font-family: var(--type-second);
  animation: anima 2s forwards;
}

@keyframes anima {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.table {
  text-align: left;
  width: 100%;
}

/* .table tr :first-child {
  padding-left: 20px;
} */

.table tr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
}

.table tr th {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: var(--c9);
  transition: 0.3s;
  width: max-content;
  padding-bottom: 12px;
  padding-top: 20px;
}

.table tr td {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--a1);
  transition: 0.3s;
  width: fit-content;
  text-align: left;
  text-transform: uppercase;
  margin: 5px 0;
}

.info {
  font-family: var(--type-second);
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  margin-top: 60px;
  color: var(--c9);
  text-align: center;
}

.menssagem,
.carregando {
  font-family: var(--type-second);
  line-height: 24px;
}

@media (max-width: 768px) {
  .containerMain {
    width: 100%;
    padding: 40px 20px;
  }
}
