.parceiros {
  padding-bottom: 200px;
  opacity: 0;
}

.parceiros h1 {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #000000;
  margin-bottom: 60px;
  padding-left: 120px;
}

.titulo > span {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #3db191;
}

.carouselContainer {
  cursor: grab;
  overflow: hidden;
}

.divItens {
  display: flex;
  align-items: center;
}

.link {
  min-height: 200px;
  min-width: 280px;
  display: flex;
  align-items: center;
}

.link img {
  width: fit-content;
  padding: 20px;
  pointer-events: none;
}

/* @keyframes scrollAnimation {
  0%,
  100% {
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(-200%);
  }
  37.5% {
    transform: translateX(-300%);
  }
  50% {
    transform: translateX(-400%);
  }
  62.5% {
    transform: translateX(-300%);
  }
  75% {
    transform: translateX(-200%);
  }
  87.5% {
    transform: translateX(-100%);
  }
} */

@media (max-width: 600px) {
  .parceiros {
    /* padding-bottom: 60px; */
    margin-bottom: 120px;
    padding-bottom: 0;
    height: auto;
  }

  
  .link {
    min-height: 200px;
    min-width: calc(50vw - 20px); /* Define a largura de cada item para 50% da largura do container (considerando a margem) */
    margin: 10px; /* Adiciona margens entre os itens */
    display: flex;
    align-items: center;
  }

  .titulo > span {
    font-size: 10vw;
    line-height: 44px;
  }

  .parceiros h1 {
    margin-bottom: 40px;
    padding: 0 20px;
    font-size: 10vw;
    line-height: 44px;
  }
  .parceiros ul {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 40px;
  }
  .parceiros li:nth-child(n + 3) {
    border-top: 2px solid #d9d9d9;
  }
  .parceiros li:nth-child(odd) {
    border-left: none;
  }
}
