.local {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 40px;
  max-width: 1400px;
  padding: 160px 120px 0 120px;
  margin: auto;
  margin-bottom: 120px;
  opacity: 0;
}

.titulo {
  grid-column: 1 / -1;
  margin-bottom: 80px;
}

h1 {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #121212;
}

span {
  color: #3db191;
}

.localizacao {
  grid-column: 1 / -1;
  grid-row: span auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 40px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.mapa {
  border-radius: 4px 0 0 4px;
  height: 514px;
}

.localizacao > :first-child {
  border-radius: 4px 0 0 4px;
}

.mapa > :first-child {
  border-radius: 4px 0 0 4px;
}

.dados {
  background: #ededed;
  margin-left: -40px;
  border-radius: 0 5px 5px 0;
  padding-top: 60px;
  padding-left: 40px;
}

.cidade {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}

.cidade span {
  width: 12px;
  height: 8px;
  background: linear-gradient(#41bd9a, #47b87a);
  border-radius: 3px;
  margin-right: 12px;
}

.cidade h2 {
  font-family: var(--type-first);
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #121212;
}

.texto {
  font-family: var(--type-second);
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #595959;
  padding-left: 24px;
  margin-bottom: 60px;
}

.dados > p {
  display: flex;
  flex-direction: row;
  font-family: var(--type-first);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #121212;
}

p > img {
  margin-right: 8px;
}

@media screen and (max-width: 600px) {
  .local {
    display: flex;
    width: 100vw;
    padding: 100px 20px 0 20px;
    flex-direction: column;
    margin-bottom: 120px;
    height: auto;
  }

  .titulo {
    margin-bottom: 40px;
  }

  .titulo h1 {
    font-size: 10vw;
    line-height: 44px;
  }

  .localizacao {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .localizacao > :first-child {
    width: 100%;
    height: 240px;
    border-radius: 4px 4px 0 0;
  }

  .mapa {
    border-radius: 4px 4px 0 0;
    /* width: 400px;
    height: 100px; */
  }

  .mapa > :first-child {
    border-radius: 4px 4px 0 0;
  }

  .dados {
    margin-left: 0;
    border-radius: 0 0 4px 4px;
    padding: 20px;
  }

  .cidade {
    margin-bottom: 20px;
  }

  .cidade h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
