.container {
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0 auto;
}

.mainContainer {
  margin-top: 2rem;
  margin-bottom: 120px;
}

.title {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 3rem;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
}

.title::after {
  content: "";
  display: block;
  width: 8px;
  height: 24px;
  background: #41bd9a;
  position: absolute;
  bottom: 12px;
  left: -12px;
  border-radius: 5px;
  z-index: -1;
}
