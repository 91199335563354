.containerProduto {
  max-width: 1400px;
  margin: auto;
  padding: 40px 120px 80px 120px;
  background: #f7f7f7;
}

.containerProdutoWpp {
  max-width: 1400px;
  margin: auto;
  padding: 0px 120px 60px 120px;
  background: #f7f7f7;
}

.pontoWpp {
  margin-bottom: 20px;
}

.pontoWpp p {
  margin-left: 32px;
  color: #707070;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.divTituloPonto {
  display: flex;
  margin-bottom: 4px;
}

.divTituloPonto p {
  color: #2e2e2e;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 8px;
}

.produto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  margin-bottom: 40px;
}

.desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.descWpp {
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  align-items: flex-start;
}

.desc h2 {
  font-family: var(--type-first);
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #121212;
  margin-bottom: 20px;
}

.descWpp h2 {
  font-family: var(--type-first);
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #121212;
  margin-bottom: 20px;
}

.spanProd {
  color: #1d82c5;
}

.spanWpp {
  color: #3db191;
}

.desc p {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #595959;
  margin-bottom: 32px;
}

.descWpp p {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #595959;
  margin-bottom: 32px;
}

.button {
  width: fit-content;
}

.button button {
  grid-column: 10 / 13;
  background: linear-gradient(#1d81c4, #2594bd);
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 16px 32px;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}

.button button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
}
.buttonWpp {
  width: fit-content;
}

.buttonWpp button {
  grid-column: 10 / 13;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 16px 32px;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}

.buttonWpp button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
}

.videoProd {
  border-radius: 5px;
  width: 600px;
  height: 380px;
  object-fit: cover;
  margin-right: -40px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.videoWpp {
  /* border-radius: 46px; */
  width: 392px;
  height: 842px;
  object-fit: cover;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  /* position: relative;
  margin-top: 10px; */
}

.divVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 888px;
}

.imgPhone {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/phone-wpp2.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 443px;
  height: 888px;
  position: absolute; /* Added position absolute */
  z-index: 2;
}

.pontos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
}

.pontosWpp {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;
}

.ponto {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.ponto p {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #595959;
}

.ponto img {
  margin-right: 8px;
  max-width: 24px;
}

.outrosProd {
  max-width: 1400px;
  margin: auto;
  padding: 120px;
  opacity: 0;
}

.outrosProd h1 {
  margin-bottom: 20px;
}

.containerOutros {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -48px;
}

.setaDir {
  padding-left: 24px;
}

.setaEsq {
  padding-right: 24px;
}

.produtos {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  overflow: hidden;
}

.outroProduto {
  transition: transform 1s ease;
  padding-right: 40px;
}

.outroProduto img {
  max-width: 360px;
  height: 220px;
  margin-bottom: 12px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.tituloProd {
  display: flex;
  align-items: center;
}

.tituloProd span {
  width: 12px;
  height: 8px;
  border-radius: 3px;
  margin-right: 8px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
}

.tituloProd p {
  font-family: var(--type-first);
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #121212;
}

.containerOutros > img:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.2s;
}

.link:hover {
  cursor: pointer;
  transform: translateY(5px);
  transition-duration: 0.4s;
}

@media screen and (max-width: 600px) {
  .containerProduto {
    width: 100vw;
    padding: 20px;
  }

  .containerProdutoWpp {
    width: 100vw;
    padding: 20px;
  }

  .produto {
    display: flex;
    flex-direction: column-reverse;
  }

  .desc {
    margin-top: 20px;
  }

  .desc h2 {
    font-size: 28px;
    line-height: 32px;
  }

  .desc p {
    font-size: 16px;
    line-height: 24px;
  }

  .produto > video {
    width: 90vw;
    height: 30vh;
    object-fit: cover;
    margin: 0;
  }

  .videoWpp{
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 36px;
  }

  .divVideo {
    width: calc(100vw - 40px);
    height: fit-content;
    padding: 4vh 4.3vw;
  }

  .imgPhone {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/phone-wpp2.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 100%;
    height: 100%;
    position: absolute; /* Added position absolute */
    z-index: 2;
  }

  .pontos {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }

  .ponto {
    margin-bottom: 8px;
  }

  .ponto p {
    font-size: 12px;
    line-height: 18px;
  }

  .ponto img {
    margin-right: 6px;
    max-width: 18px;
  }

  .outrosProd {
    width: 100%;
    margin: auto;
    padding: 80px 20px;
  }

  .containerOutros {
    width: 100%;
    margin: 0;
  }

  .setaDir {
    padding-left: 16px;
  }

  .setaEsq {
    padding-right: 16px;
  }

  .outroProduto {
    justify-content: center;
    align-items: center;
  }

  .outroProduto img {
    width: 67vw;
    height: auto;
  }

  .outrosProd h1 {
    font-size: 10vw;
    line-height: 44px;
  }

  .descWpp {
    padding: 40px 10px 40px;
  }

  .pontosWpp {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
}
