/* .container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
} */

.modal {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 60px;
  max-width: 760px;
  max-height: 80vh;
  padding: 60px 100px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
  outline: none;
}

.modal h2 {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 20px;
}

.modal p {
  font-family: var(--type-second);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 32px;
}

.modal button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  border-style: none;
  background: #b3b3b3;
  border-radius: 5px;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #595959;
  align-self: flex-end;
  width: fit-content;
}

/* Personaliza a barra de rolagem */
::-webkit-scrollbar {
  width: 10px;
}

/* Personaliza a faixa da barra de rolagem */
::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 5px;
}

/* Personaliza o polegar da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 5px;
}

/* Personaliza o polegar da barra de rolagem quando estiver em hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

@media screen and (max-width: 600px) {
  .modal {
    padding: 32px;
    margin: 60px 20px;
  }

  .modal h2 {
    font-size: 24px;
    line-height: 28px;
  }

  .modal p {
    font-size: 16px;
    line-height: 24px;
  }

  .modal button {
    font-size: 16px;
    line-height: 24px;
  }
}
