.menu {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 560px;
  max-width: 380px;
  padding: 60px 40px 0 40px;
  background: var(--v3);
}

.divTitulo {
  margin-bottom: 40px;
}

.divTitulo h1 {
  font-family: var(--type-first);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: var(--c2);
}

.divTitulo h1 span {
  color: var(--v1);
}

.sep {
  display: inline-block;
  background: var(--c4);
  width: 340px;
  height: 2px;
  margin-left: -20px;
  border-radius: 4px;
  margin-top: 20px;
}

.divMenu {
  display: flex;
  flex-direction: column;
}

.divItem {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  margin-left: 20px;
}

.marc {
  display: inline-block;
  background: var(--v1);
  width: 12px;
  height: 8px;
  margin-right: 4px;
  border-radius: 4px;
}

.itemMenu {
  font-family: var(--type-first);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: var(--c2);

  transition: 0.3s;
  width: fit-content;
}

.itemMenu:hover {
  transform: translateX(5px);
  color: var(--v1);
}

.selected {
  font-family: var(--type-first);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  transition: 0.3s;
  color: var(--v1);
}

.menuConv {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 8px;
  transition: 0.5s;
}

.itemConvenio {
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
  transition: 0.3s;
  color: var(--c3);
}

.selectedConv {
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
  transition: 0.3s;
  color: var(--a1);
}

.itemConvenio:hover {
  transform: translateX(5px);
  color: var(--a1);
}

.sair {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
  transition: 0.3s;
}

.sair p {
  font-family: var(--type-second);
  color: var(--c3);
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  /* transition: .3s; */
}

.svg {
  margin-left: 8px;
  width: 32px;
  height: 32px;
  fill: var(--c3);
  /* transition: .3s; */
}

.sair:hover {
  cursor: pointer;
  transform: translateX(5px);
}

.sair:hover .svg,
.sair:hover p {
  fill: var(--vr1);
  color: var(--vr1);
}

.menuToggle {
  display: none;
  color: var(--c2);
  border: none;
  cursor: pointer;
  height: 40px;
  position: absolute;
  margin: 12px 0;
  z-index: 2;
}

.botaoMenuNav {
  display: flex;
  margin-left: 12px;
  color: var(--v1);
  font-weight: 700;
  font-size: 20px;
}

.menuToggleOpen {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.menuToggleClosed {
  position: fixed;
  top: 20px;
  left: 10px;
  cursor: pointer;
  z-index: 3; /* Certifique-se de que está acima do conteúdo */
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.menu.open {
  transform: translateY(0);
  opacity: 1;
}

.menu.closed {
  transform: translateY(-200%);
  opacity: 0;
}

.menuOpenButton {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 100;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.menuCloseButton {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
  margin: 12px;
}
@media (max-width: 768px) {
  .menuToggle {
    display: block;
    background: transparent;
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: var(--v3); */
    padding: 2rem 1rem;
    z-index: 999; /* Garante que fica acima do conteúdo */
    opacity: 0;
    pointer-events: none; /* Impede interação quando fechado */
  }

  .menu.open {
    opacity: 1;
    pointer-events: all; /* Permite interação quando aberto */
  }

  .footer {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 40px 20px 0 20px;
  }
}
