.footer {
  display: grid;
  overflow: visible;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  margin-top: 0;
  padding: 60px 120px 0px 120px;
  max-width: 1400px;
  align-items: initial;
  height: 528px;
  margin: auto;
  background: #f7f7f7;
}

.logofooter {
  grid-column-start: 1;
  grid-column-end: span 3;
  width: 136px;
  height: 32px;
}

.infoDiv,
.contatoDiv {
  font-family: var(--type-second);
  margin-bottom: 0;
  padding-bottom: 0;
}

.infoDiv {
  margin-left: 200px;
}

.informacoes,
.contato {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 32px;
  color: #121212;
}

.nav {
  display: flex;
  flex-direction: column;
}

.itemInfo {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #595959;
  width: fit-content;
}

.itemContato {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #595959;
  margin-bottom: 32px;
}

.itemMedia {
  margin-right: 32px;
}

.media {
  display: flex;
  justify-content: left;
  margin-bottom: 60px;
}

.contatoDiv {
  grid-column: 4 / 9;
}

.contatoIconsDiv {
  display: flex;
  grid-column: 4 / 4;
  flex-direction: column;
  padding-top: 2px;
  margin-right: 12px;
}

.dadosContato {
  display: flex;
  flex-direction: row;
  margin-left: -36px;
}

.contatoIcons {
  min-width: 24px;
  margin-bottom: 4px;
}

.endereco {
  margin-top: 28px;
}

.copyright {
  grid-column: 1 / 12;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #9c9c9c;
  margin-bottom: 32px;
}

.itemInfo:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.2s;
}

.selected {
  font-size: 18px;
  line-height: 32px;
  color: #595959;
  width: fit-content;
  font-weight: 600;
  text-decoration: underline 2px;
  text-underline-offset: 3px;
  scale: 1.05;
}

.containerFooter {
  grid-column: 4 / -1;
  display: flex;
}

@media (max-width: 600px) {
  .footer {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 40px 20px 0 20px;
  }

  .containerFooter {
    display: flex;
    margin-top: 32px;
  }

  .dadosContato {
    margin: 0;
  }

  .informacoes,
  .contato {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .contatoDiv h2 {
    padding-left: 36px;
  }

  .itemContato {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #595959;
    margin-bottom: 32px;
  }

  .copyright {
    font-size: 12px;
    line-height: 32px;
    color: #9c9c9c;
    margin-bottom: 20px;
  }

  .infoDiv {
    margin-left: 20px;
  }

  .itemInfo {
    font-size: 16px;
    line-height: 32px;
    color: #595959;
    width: fit-content;
  }

  .media {
    margin-left: 36px;
  }
}

@media (max-width: 470px) {
  .contatoIcons:nth-child(3) {
    margin-top: 28px;
  }

  .infoDiv {
    margin-left: 10px;
  }
}
