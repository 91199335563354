.header {
  display: flex;
  margin-top: 0;
  padding: 0px 120px;
  grid-column-gap: 40px;
  max-width: 1400px;
  align-items: center;
  height: 96px;
  margin: auto;
  background: #f7f7f7;
}

.nav {
  display: flex;
  width: 1160px;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 200px;
  height: 48px;
}

.menu {
  display: flex;
  justify-content: space-between;
  font-family: var(--type-second);
  color: #121212;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  align-items: center;
}

.itensMenu {
  display: flex;
  justify-content: space-between;
  font-family: var(--type-second);
  color: #121212;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  align-items: center;
}

.sep {
  display: block;
  background: linear-gradient(#41bd9a, #47b87a);
  width: 2px;
  height: 8px;
  border-radius: 5px;
  margin-right: 15px;
}

.botoes {
  display: flex;
  align-items: center;
}

.linkButton {
  width: fit-content;
}

.linkButton:first-child {
  margin-right: 20px;
}

.button {
  background: linear-gradient(#1d81c4, #2594bd);
  font-family: var(--type-second);
  font-weight: 800;
  width: auto;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 20px;
}

.botoes > :first-child .button {
  padding: 12px 20px;
  color: #1d82c5;
  background: #f7f7f7;
  border: 2px solid #1d82c5;
}

.button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
}

.botoes > :first-child .button:hover {
  color: #fff;
  background: linear-gradient(#1d81c4, #2594bd);
  transform: scale(1);
}

.item {
  margin-right: 15px;
}

.item:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.2s;
}

.selected {
  font-weight: 600;
  text-decoration: underline 2px;
  text-underline-offset: 4px;
  scale: 1.05;
  margin-right: 15px;
}

.checkboxMenu {
  display: none;
}

@keyframes showElement {
  from {
    display: none;
    transform: translateX(100vw);
  }
  to {
    display: flex;
    transform: translateX(-100vw);
  }
}

@keyframes hideElement {
  from {
    left: 0;
    display: flex;
  }
  to {
    left: 100%;
    display: none;
  }
}

@media (max-width: 600px) {
  .header {
    display: flex;
    padding: 0 20px;
    height: 60px;
    width: 100vw;
    background: #f7f7f7;
    align-items: center;
  }

  .logo {
    width: 120px;
    height: 24px;
    margin: 0;
  }

  .botoes {
    display: none;
  }

  .sep {
    display: none;
  }

  .nav {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
  }

  .itensMenu {
    display: none;
    position: absolute;
    top: 60px;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    align-items: center;
    left: 100%;
    /* transform: translateX(100vw);
    transition: 0.5s ease transform; */
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .escondido {
    animation: hideElement 0.5 ease-out forwards;
  }

  .itensMenu > :last-child {
    border: none;
  }

  .item {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 8px 0;
    width: 100%;
    background-color: #f7f7f7;
    border-bottom: 1px solid #cccccc;
  }

  .selected {
    display: flex;
    justify-content: center;
    padding: 8px 0;
    margin: 0;
    width: 100%;
    text-decoration: none;
    color: #ffffff;
    background-color: #1d82c5;
    transition: 0.3s ease;
    border: none;
    scale: 1;
  }

  .menuButtonContainer {
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 3%;
    display: block;
    height: 22px;
    width: 30px;
  }

  .menuButtonContainer span {
    position: absolute;
    display: block;
    height: 5px;
    width: 100%;
    background: #707070;
    border-radius: 30px;
    transition: 0.25s ease-in-out;
  }

  .menuButtonContainer span:nth-child(1) {
    top: 0;
  }

  .menuButtonContainer span:nth-child(2) {
    top: 8px;
  }

  .menuButtonContainer span:nth-child(3) {
    top: 16px;
  }

  .checkboxMenu:checked + label span:nth-child(1) {
    transform: rotate(-45deg);
    background: brown;
    top: 8px;
  }
  .checkboxMenu:checked + label span:nth-child(2) {
    display: none;
  }
  .checkboxMenu:checked + label span:nth-child(3) {
    transform: rotate(45deg);
    background: brown;
    top: 8px;
  }

  .checkboxMenu:checked ~ nav .itensMenu {
    display: flex;
    /* transform: translateX(-100%);
    transition: 0.5s ease transform; */
    animation-name: showElement;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
}
