.containerMain {
  width: 100%;
  padding-left: 60px;
  margin-top: 80px;
}

.containerMain h3 {
  color: var(--c11);
  font-family: var(--type-first);
  font-size: 36px;
  line-height: 40px;
  font-weight: 800;
  margin-bottom: 40px;
  white-space: nowrap;
}

.modulos {
  padding-left: 20px;
  padding-bottom: 20px;
}

.containerMain h4 {
  color: var(--c8);
  font-family: var(--type-second);
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 12px;
}

.Glinks {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-top: 4px;
}

.links {
  color: var(--a1);
  font-family: var(--type-second);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 4px;
}

@media (max-width: 768px) {
  .containerMain {
    width: 100%;
    padding: 40px 20px;
  }
}
