.MainContainer {
  font-family: var(--type-second);
}

.titulo {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  color: #121212;
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
}
.atualizacao {
  border-bottom: 2px solid hsla(0, 0%, 35%, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.item {
  border-bottom: 2px solid hsla(0, 0%, 35%, 0.1);
  padding-bottom: 20px;
  max-width: 960px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.subtitle {
  margin: 0 auto;
  max-width: 960px;
}
.h2SubTitle {
  margin-bottom: 8px;
  color: #1d81c4;
  margin-bottom: 20px;
}

.contatos {
  max-width: 960px;
  margin: 0 auto;
  margin-bottom: 80px;
  margin: 0 auto;
  padding-bottom: 80px;
}

.DoisPontos {
  font-weight: 600;
  color: #121212;
}

.contato {
  margin-bottom: 12px;
  margin-top: 12px;
}

@media (max-width: 1024px) {
  .titulo {
    font-size: 36px;
    line-height: 48px;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .item,
  .subtitle,
  .contatos {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .titulo {
    font-size: 28px;
    line-height: 38px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .h2SubTitle {
    font-size: 22px;
  }

  .item {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .contatos {
    padding-bottom: 40px;
  }
}

@media (max-width: 480px) {
  .titulo {
    font-size: 24px;
    line-height: 32px;
  }

  .h2SubTitle {
    font-size: 20px;
  }

  .item,
  .subtitle,
  .contatos {
    max-width: 100%;
  }

  .MainContainer {
    padding: 0 12px;
  }
}
