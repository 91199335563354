.containerIframe {
  width: 1400px;
  padding: 0 120px;
  margin: auto;
  background: #f7f7f7;
}

.iframe {
  padding: 60px 0;
  margin-left: -120px;
  width: 1400px;
  height: 100vh;
  border: none;
}
