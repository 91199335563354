.containerForm {
  margin: 100px 20px;
  width: 100%;
}

.containerForm button {
  background: var(--g2);
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 16px 32px;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}

.containerForm button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
}

.form {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
}

.checkbox {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #595959;
}

.checkbox label {
  margin-left: 8px;
}

.form button {
  font-family: var(--type-second);
  font-weight: 500;
  max-width: 232px;
  font-size: 18px;
  line-height: 24px;
  color: #f7f7f7;
  padding: 16px 32px;
  border-radius: 5px;
  margin: 20px 0;
  border: none;
  background: var(--g1);
}

.form button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s;
}

.form button:disabled {
  background: rgba(65, 189, 154, 0.7);
}

.aviso {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.aviso textarea:hover,
.aviso textarea:focus {
  outline: none;
  border-color: #41bd9a;
  background: white;
  box-shadow: 0 0 0 3px rgba(65, 189, 154, 0.3);
}

.aviso label {
  display: block;
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  padding-bottom: 4px;
}

.aviso textarea {
  background: #f7f7f7;
  border: 1px solid #ededed;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  height: 160px;
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  transition: 0.2s;
  padding: 12px 12px 0 12px;
}

.modal {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  margin: 40vh auto;
  width: 40%;
  border-radius: 5px;
  background: #ededed;
  padding: 20px;
}

.modal button {
  background: linear-gradient(#1d81c4, #2594bd);
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 8px 20px;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}

.modal button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
}

.modal h2 {
  margin-bottom: 12px;
  color: #121212;
}

.modal h2 > span {
  color: #1d81c4;
}

.modal p {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.modal p span {
  font-weight: 600;
  color: #121212;
}

.previa {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 60px;
  max-width: 760px;
  max-height: 80vh;
  padding: 60px 100px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
  outline: none;
}

.previa h2 {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 20px;
}

.previa p {
  font-family: var(--type-second);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 32px;
}

.previa button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  border-style: none;
  background: #b3b3b3;
  border-radius: 5px;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #595959;
  align-self: flex-end;
  width: fit-content;
}

@media screen and (max-width: 600px) {
  .previa {
    padding: 32px;
    margin: 60px 20px;
  }

  .previa h2 {
    font-size: 24px;
    line-height: 28px;
  }

  .previa p {
    font-size: 16px;
    line-height: 24px;
  }

  .previa button {
    font-size: 16px;
    line-height: 24px;
  }
}
