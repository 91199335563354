@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: auto;
  color: #333;
  --type-first: Raleway, sans-serif;
  --type-second: Roboto;
  --g1: linear-gradient(180deg, #41BD9A 0%, #47B87A 100%);
  --g2: linear-gradient(180deg, #1D81C4 0%, #2594BD 100%);
  --a1: #1D81C4;
  --v1: #3DB191;
  --v3: #273D37;
  --c2: #EDEDED;
  --c3: #DEDEDE;
  --c4: #CCCCCC;
  --c8: #595959;
  --c11: #121212;
  --v2: #AC0000;
  --vr1: #E30016;

  padding-top: 0;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}

.col2 {
  grid-column: span 2;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-40px);
  animation: animeLeft 1s forwards;
}

.animeLeftProd {
  opacity: 0;
  transform: translateX(-40px);
  animation: animeLeft 1s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

@media (max-width: 600px) {
  body {
    max-width: 100vw;
  }
}

/* #copiar {
  background-image: url('../src/assets/copiar.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  padding-right: 30px;
} */