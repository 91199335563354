.containerMain {
  width: 100%;
  padding-left: 60px;
  margin-top: 80px;
}

.divForm {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px auto; */
  max-width: 440px;
  position: relative;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translateY(-50%); */
}

.divForm h3 {
  color: var(--c11);
  font-family: var(--type-first);
  font-size: 36px;
  line-height: 40px;
  font-weight: 800;
  margin-bottom: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 60px 20px 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 200px;
}

.form button {
  font-family: var(--type-second);
  margin-top: 12px;
  margin-bottom: 32px;
  font-weight: 500;
  align-self: center;
  width: fit-content;
  font-size: 18px;
  line-height: 24px;
  color: #f7f7f7;
  padding: 16px 32px;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  background: linear-gradient(#41bd9a, #47b87a);
}

.form button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s;
}

.error {
  color: red;
  align-self: center;
  font-family: var(--type-second);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 20px;
}

.desInvalido {
  color: var(--c8);
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.msgCopiada {
  color: green;
  text-align: center;
  font-family: var(--type-second);
  animation: anima 2s forwards;
}

@keyframes anima {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .containerMain {
    width: 100%;
    padding: 40px 20px;
  }

  .menu {
    border-right: none;
    border-bottom: 2px solid #41bd9a;
  }

  .divForm {
    margin-top: 1rem; /* Espaçamento entre o menu e o formulário */
  }
}
