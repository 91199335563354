.containerMain {
  width: 100%;
  padding-left: 60px;
  margin-top: 80px;
}

.containerMain h3 {
  color: var(--c11);
  font-family: var(--type-first);
  font-size: 36px;
  line-height: 40px;
  font-weight: 800;
  margin-bottom: 40px;
  white-space: nowrap;
}

.links {
  color: var(--a1);
  font-size: 18px;
  font-weight: 24px;
  padding-left: 20px;
  font-family: var(--type-second);
}

.containerMain a {
  display: flex;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .containerMain {
    width: 100%;
    padding: 40px 20px;
  }
}
