.containerMain {
  width: 100%;
}

.divForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px auto;
  max-width: 440px;
}

.divForm h3 {
  color: var(--a1);
  font-family: var(--type-first);
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 60px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.esqueceu {
  color: #1D82C5;
  font-family: var(--type-second);
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  align-self: flex-end;
  margin-top: -16px;
}

.form button {
  font-family: var(--type-second);
  margin-top: 32px;
  font-weight: 500;
  align-self: center;
  width: fit-content;
  font-size: 18px;
  line-height: 24px;
  color: #f7f7f7;
  padding: 16px 32px;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  background: linear-gradient(#41bd9a, #47b87a);
}


.error {
  color: red;
  align-self: center;
  font-family: var(--type-second);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 20px;
}

.invalidCnpj {
  color: red;
  align-self: center;
  font-family: var(--type-second);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 20px;
}

.form button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s;
}

.modal {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 30%;
  border-radius: 12px;
  background: #ededed;
  padding: 40px  60px;
}

.modal button {
  background: linear-gradient(#1d81c4, #2594bd);
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 8px 20px;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}

.modal button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
}

.modal h2 {
  margin-bottom: 12px;
  color: #121212;
}

.modal h2 > span {
  color: #1d81c4;
}

.modal p {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #595959;
  text-align: center;
}

.modal p span {
  font-weight: 600;
  color: #121212;
}