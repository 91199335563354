.contato {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  max-width: 1400px;
  padding: 40px 120px 0px 120px;
  margin: auto;
  background: linear-gradient(to bottom, #f7f7f7 50%, white 50%);
}

@media screen and (max-width: 600px) {
  .contato {
    display: flex;
    width: 100vw;
    padding: 0 20px;
  }
}
