.produtos {
  display: grid;
  /* grid-template-columns: repeat(12, 1fr); */
  margin-top: 0;
  padding: 0px 120px 0px 120px;
  max-width: 1400px;
  align-items: start;
  margin: auto;
}

.tituloProd {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #121212;
  grid-column: 1 / -1;
  margin-bottom: 40px;
}

.tituloProd span {
  color: #41bd9a;
}

@media (max-width: 600px) {
  .produtos {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    margin: 0;
  }
  .tituloProd {
    font-size: 10vw;
    line-height: 44px;
    padding: 0 20px;
  }
}
