.cert {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  margin-top: 0;
  padding: 0px 120px 0px 120px;
  grid-column-gap: 40px;
  max-width: 1400px;
  align-items: start;
  margin: auto;
  background: #fff;
  height: 580px;
  margin-bottom: 240px;
}

.infoCert {
  margin-top: 120px;
  grid-column: 1/7;
}

.tituloCert {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #121212;
  margin-bottom: 32px;
}

.ponto {
  color: #41bd9a;
}

.descCert {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #404040;
  margin-bottom: 20px;
}

.destaqueCert {
  font-weight: 600;
  color: #121212;
}

.orcButton {
  font-family: var(--type-second);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #f7f7f7;
  padding: 16px 32px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(#41bd9a, #47b87a);
}

.orcButton:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s;
}

.imgDiv {
  grid-column: 7 / -1;
  min-height: 560px;
}

.logoParc {
  margin-top: 60px;
}

@media (max-width: 600px) {
  .cert {
    display: flex;
    width: 100%;
    height: auto;
    padding: 0px 40px;
    margin-bottom: 120px;
    flex-direction: column-reverse;
  }

  .infoCert {
    margin-top: 8vh;
    margin-top: 40px;
  }

  .tituloCert {
    font-size: 9vw;
    line-height: 48px;
  }

  .ponto {
    color: #41bd9a;
  }

  .descCert {
    font-family: var(--type-second);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #404040;
    margin-bottom: 40px;
  }

  .destaqueCert {
    font-weight: 600;
    color: #121212;
  }

  /* .orcButton {
    margin-bottom: 10vh;
  } */

  .orcButton:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.3s;
  }

  .imgDiv {
    height: auto;
    min-height: auto;
  }
}
