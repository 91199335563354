.whatsapp {
  width: auto;
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: #f7f7f7;
  margin: 20px 20px;
  border-radius: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.info p:first-child {
  padding-bottom: 4px;
  font-family: var(--type-first);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #121212;
}

.info p {
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #595959;
}

.link {
  margin: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link img {
  margin-right: 12px;
  width: 64px;
  height: 64px;
}

.whatsapp:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.2s;
}

@media (max-width: 600px) {
  .info {
    display: none;
  }

  .link img {
    margin: 0;
    width: 48px;
    height: 48px;
  }
}
