.sobre {
  max-width: 1400px;
  padding: 60px 120px 80px 120px;
  margin: auto;
}

.sobre > :last-child {
  border: none;
}

.item {
  margin-bottom: 20px;
  border-bottom: 2px solid hsla(0, 0%, 35%, 0.1);
  padding-bottom: 20px;
}

.item p {
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #595959;
}

.titulo {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--type-first);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #121212;
  margin-bottom: 20px;
  margin-left: -16px;
}

.titulo span {
  margin-right: 8px;
  width: 8px;
  height: 12px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
  border-radius: 3px;
}

@media (max-width: 600px) {
  .sobre {
    width: 100vw;
    padding: 60px 40px;
  }

  .sobre > :last-child {
    border: none;
  }

  .item p {
    font-size: 16px;
    line-height: 24px;
  }

  .titulo {
    font-size: 20px;
    line-height: 32px;
  }
}
