.orcamento {
  padding: 40px 120px 0 120px;
  border-radius: 5px;
}

.form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  border-radius: 5px;
}

.modulos {
  grid-column: 1 / 6;
  background: #ededed;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  padding-left: 60px;
  padding-top: 60px;
  border-radius: 5px 0 0 5px;
}

.aviso {
  margin-top: 32px;
  margin-right: 40px;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #595959;
}

.titulo {
  font-family: var(--type-first);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #121212;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.titulo span {
  width: 4px;
  height: 8px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
  border-radius: 2px;
  margin-right: 8px;
  margin-left: -10px;
}

.modulos label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #595959;
}

.modulos form {
  margin-top: 20px;
}

.modulos label input {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.orcamentoForm {
  grid-column: 6/13;
  background: white;
  margin-left: -40px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 60px;
  padding-right: 60px;
  border-radius: 0 5px 5px 0;
}

.orcamentoForm input {
  padding: 12px 0 12px 12px;
}

.orcamentoForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.orcamentoForm button {
  text-transform: uppercase;
  margin-top: 12px;
}

.estado {
  display: flex;
  flex-direction: column;
}

.estado label {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  margin-bottom: 4px;
}

.estado select {
  width: 280px;
  background: #f7f7f7;
  border-radius: 5px;
  border: 1px solid #ededed;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  padding: 12px;
}

.estado select:focus,
.estado select:hover {
  outline: none;
  border-color: #41bd9a;
  background: white;
  box-shadow: 0 0 0 3px rgba(65, 189, 154, 0.3);
}

.orcamentoForm button {
  font-family: var(--type-second);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #f7f7f7;
  padding: 16px 32px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(#41bd9a, #47b87a);
}

.orcamentoForm button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s;
}

.orcamento button:disabled {
  background: rgba(65, 189, 154, 0.7);
}

.modal {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 40vh auto;
  width: 40%;
  border-radius: 5px;
  background: #ededed;
  padding: 20px 40px;
}

.modal button {
  background: linear-gradient(#1d81c4, #2594bd);
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 8px 20px;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}

.modal button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
}

.modal h2 {
  text-align: center;
  margin-bottom: 12px;
  color: #121212;
}

.modal h2 > span {
  color: #1d81c4;
}

.modal p {
  justify-content: center;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.modal p span {
  font-weight: 600;
  color: #121212;
}

@media screen and (max-width: 600px) {
  .orcamento {
    padding: 0;
    border-radius: 5px;
    padding-top: 20px;
    padding-left: 0;
    margin: 0 20px;
  }

  .form {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 100%;
  }

  .modulos {
    grid-column: 1 / -1;
    background: #ededed;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 20px;
    border-radius: 5px 5px 0 0;
  }

  .titulo {
    font-size: 14px;
  }

  .modulos form {
    margin-top: 20px;
  }

  .modulos label input {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  .orcamentoForm {
    display: flex;
    flex-direction: column;
    background: white;
    margin-left: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    padding: 40px 20px;
    border-radius: 0 0 5px 5px;
    width: 100%;
  }

  .orcamentoForm input {
    padding: 12px 0 12px 12px;
    width: 100%;
  }

  .orcamentoForm button {
    margin-top: 32px;
  }

  .modal {
    width: 90vw;
    margin: 30vh 5vw;
  }
}
