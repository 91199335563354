.stories img,
.stories video {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.stories {
  display: grid;
  grid-column: 1 / -1;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  max-width: 1160px;
  height: 700px;
  background: gray;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.1);
}

.storyContainer {
  display: grid;
  grid-area: 1 / -1;
}

.navButtons {
  grid-area: 1/1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}

.navButtons button {
  opacity: 0;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.slideThumb {
  display: flex;
  position: absolute;
  width: 100%;
  pointer-events: none;
}

.slideThumb > span {
  flex: 1;
  display: block;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  margin: 5px;
  border-radius: 4px;
  overflow: hidden;
  isolation: isolate;
}

.slideThumb .thumbItem.active {
  display: block;
  height: inherit;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  animation: 3s thumb forwards linear running;
  transform: translateX(-100%);
}

.slideThumb .thumbItem.paused {
  animation-play-state: paused;
}

.modulo {
  display: grid;
  align-items: self-start;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  background: #f7f7f7;
  max-width: 1160px;
  margin-bottom: 180px;
  border-radius: 0 0 5px 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.pontos {
  top: 0;
  grid-column: 7 / 10;
  align-items: start;
  font-family: var(--type-first);
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #2e2e2e;
  margin-bottom: 40px;
  margin-top: -40px;
}

.titulo {
  font-family: var(--type-first);
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  color: #121212;
}

.desc {
  margin-top: 20px;
  padding-left: 60px;
  grid-column: 1/6;
  font-family: var(--type-second);
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #595959;
  margin-bottom: 40px;
}

.marcador {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.marcVert {
  width: 8px;
  height: 12px;
  margin-right: 8px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
  border-radius: 3px;
}

.tituloMarc {
  margin-top: 40px;
  display: flex;
  margin-left: 40px;
  grid-column: 1 / 6;
  align-items: center;
}

.marcHorz {
  width: 12px;
  height: 8px;
  margin-right: 8px;
  border-radius: 3px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
}

.saibaMaisLink {
  grid-column: 10/13;
  margin-top: -40px;
  width: fit-content;
  height: fit-content;
}

.saibaMais {
  grid-column: 10/13;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 16px 32px 16px 32px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
  border-radius: 5px;
  border: none;
  color: #f7f7f7;
}

.saibaMais:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s;
}

.loading {
  max-width: 1160px;
  height: 700px;
  background-image: linear-gradient(90deg, #eee 0px, #fff 50%, #eee 100%);
  background-color: #eee;
  background-size: 200%;
  animation: skeleton 1.5s infinite linear;
}

@keyframes thumb {
  to {
    transform: initial;
  }
}

@keyframes skeleton {
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
}

@media (max-width: 600px) {
  .stories {
    width: 96vw;
    height: 40vh;
    margin: 0 2vw;
  }

  .modulo {
    display: flex;
    flex-direction: column;
    width: 96vw;
    margin: 0 2vw;
    margin-bottom: 120px;
  }

  .tituloMarc {
    margin: 20px;
  }

  .titulo {
    font-size: 28px;
    line-height: 32px;
  }

  .desc {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 20px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .pontos {
    padding-left: 20px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  .saibaMaisLink {
    align-self: flex-end;
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
