.contatoForm {
  grid-column: 6 / -1;
  background-color: white;
  margin-left: -40px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  padding-bottom: 60px;
  border-radius: 0 5px 5px 0;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  padding-top: 40px;
  padding-left: 60px;
  padding-right: 60px;
}

.email input {
  width: 580px;
  height: 48px;
  padding-left: 12px;
}

.mensagem {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.mensagem label {
  display: block;
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  padding-bottom: 4px;
}

.mensagem textarea {
  background: #f7f7f7;
  border: 1px solid #ededed;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  height: 160px;
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  transition: 0.2s;
  padding: 12px 12px 0 12px;
}

.mensagem textarea:hover,
.mensagem textarea:focus {
  outline: none;
  border-color: #41bd9a;
  background: white;
  box-shadow: 0 0 0 3px rgba(65, 189, 154, 0.3);
}

.form button {
  font-family: var(--type-second);
  font-weight: 500;
  max-width: 232px;
  font-size: 18px;
  line-height: 24px;
  color: #f7f7f7;
  padding: 16px 32px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(#41bd9a, #47b87a);
}

.form button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s;
}

.form button:disabled {
  background: rgba(65, 189, 154, 0.7);
}

.modal {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 40%;
  border-radius: 12px;
  background: #ededed;
  padding: 40px 60px;
}

.modal button {
  background: linear-gradient(#1d81c4, #2594bd);
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 8px 20px;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}

.modal button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
}

.modal h2 {
  margin-bottom: 12px;
  color: #121212;
}

.modal h2 > span {
  color: #1d81c4;
}

.modal p {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.modal p span {
  font-weight: 600;
  color: #121212;
}

@media screen and (max-width: 600px) {
  .contatoForm {
    width: 100%;
    margin: 0;
    border-radius: 5px;
    padding-bottom: 40px;
  }

  .form {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .email input {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
  }

  .mensagem textarea {
    height: 120px;
  }

  .modal {
    width: 90vw;
    margin: 30vh 5vw;
  }
}
