.suporte {
  max-width: 1400px;
  padding: 60px 120px 80px 120px;
  margin: auto;
}

.suporte > :last-child {
  border: none;
}

.item {
  margin-bottom: 20px;
  border-bottom: 2px solid hsla(0, 0%, 35%, 0.1);
  padding-bottom: 20px;
}

.titulo {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--type-first);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #121212;
  margin-bottom: 20px;
  margin-left: -16px;
}

.links {
  display: flex;
  padding: 20px 0;
}

.links > :first-child {
  margin-left: 0;
}

.links a {
  width: fit-content;
  margin: 0 20px;
}

.links a button {
  grid-column: 10 / 13;
  background: #1d81c4;
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  padding: 16px 32px;
  line-height: 24px;
}

.links a button:hover {
  cursor: pointer;
  transform: translatey(-5px);
  transition: 0.3s;
  background: #104669;
}

.span1 {
  margin-right: 8px;
  width: 8px;
  height: 12px;
  border-radius: 3px;
  background: linear-gradient(180deg, #1d81c4 0%, #2594bd 100%);
}

.links2 {
  display: flex;
  padding: 20px 0;
}

.links2 > :first-child {
  margin-left: 0;
}

.links2 a {
  width: fit-content;
  margin: 0 20px;
}

.links2 a button {
  grid-column: 10 / 13;
  background: #41bd9a;
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  padding: 16px 32px;
  line-height: 24px;
}

.links2 a button:hover {
  cursor: pointer;
  transform: translatey(-5px);
  transition: 0.3s;
  background: #1e5a4a;
}

.span2 {
  margin-right: 8px;
  width: 8px;
  height: 12px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
  border-radius: 3px;
}

.links3 {
  display: flex;
  padding: 20px 0;
}

.links3 > :first-child {
  margin-left: 0;
}

.links3 a {
  width: fit-content;
  margin: 0 20px;
}

.links3 a button {
  grid-column: 10 / 13;
  background: #e3365f;
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  padding: 16px 32px;
  line-height: 24px;
}

.links3 a button:hover {
  cursor: pointer;
  transform: translatey(-5px);
  transition: 0.3s;
  background: #92213b;
}

.span3 {
  margin-right: 8px;
  width: 8px;
  height: 12px;
  background: #e3365f;
  border-radius: 3px;
}

.modulos {
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 0;
}

.modulos label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  padding-left: 0;
}

.modulos label input {
  display: flex;
  margin: 0;
  margin-right: 8px;
  align-self: center;
}

.exe {
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 0;
  margin-bottom: 32px;
}

.exe label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  padding-left: 0;
}

.exe label input {
  display: flex;
  margin: 0;
  margin-right: 8px;
  align-self: center;
}

.span0 {
  margin-right: 8px;
  width: 8px;
  height: 12px;
  background: #7653bd;
  border-radius: 3px;
}

.download h3 {
  display: block;
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  padding-bottom: 2px;
}

.download button {
  grid-column: 10 / 13;
  background: #7653bd;
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  padding: 16px 32px;
  line-height: 24px;
}

.download button:hover {
  cursor: pointer;
  transform: translatey(-5px);
  transition: 0.3s;
  background: #4c347c;
}

.download input {
  max-width: 400px;
}
