.contatoDivC {
  grid-column: 1 / 6;
  background: #ededed;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px 0 0 5px;
  padding-top: 60px;
  padding-left: 40px;
}

.contatoDivC h2 {
  font-family: var(--type-first);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
}

.itemContato {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #595959;
  margin-top: 28px;
}

.local {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  margin-bottom: 60px;
  margin-top: 32px;
}

.dadosContato {
  display: flex;
  flex-direction: row;
}

.contatoIconsDivC {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  margin-right: 12px;
}

.contatoIconsC {
  margin-bottom: 8px;
}

.itemMediaC {
  margin-right: 32px;
}

.mediaC {
  padding-left: 36px;
}

.emailC {
  margin-top: 32px;
}

.enderecoC {
  margin-top: 36px;
}

@media screen and (max-width: 600px) {
  .contatoDivC {
    display: none;
  }
}
