.resumo {
  display: grid;
  /* justify-content: center; */
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  max-width: 1400px;
  padding: 0 120px 0 120px;
  margin: auto;
  background: #ededed;
  opacity: 0;
  margin-bottom: 180px;
}

.texto {
  margin-top: 40px;
  grid-column: 1 / 7;
  padding-bottom: 64px;
}

.divButton {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.saibaMaisLink {
  grid-column: 10/13;
  width: fit-content;
  height: fit-content;
}

.saibaMais {
  grid-column: 10/13;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 16px 32px 16px 32px;
  background: linear-gradient(180deg, #41bd9a 0%, #47b87a 100%);
  border-radius: 5px;
  border: none;
  color: #f7f7f7;
}

.saibaMais:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s;
}

.spanTexto {
  color: var(--C11, #121212);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.subTitulo {
  font-family: var(--type-second);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #595959;
  margin-bottom: 20px;
}

.titulo {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #000000;
  margin-bottom: 32px;
}

.titulo > span {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #3db191;
}

.desc {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #595959;
  max-width: 550px;
  margin-bottom: 32px;
}

.imgWpp {
  grid-column: 7 / -1;
  margin: -60px -40px -60px 0;
  min-width: 600px;
  height: 960px;
}

@media (max-width: 600px) {
  .imgWpp {
    display: none;
  }

  .texto {
    margin-left: 0;
  }

  .resumo {
    display: flex;
    width: 100%;
    padding: 0 40px;
    height: auto;
    grid-column-gap: 0;
    margin-bottom: 120px;
  }

  .desc {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  }

  .spanTexto {
    color: var(--C11, #121212);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .dif {
    flex-direction: column;
    margin-left: 0;
    margin-bottom: 40px;
  }

  .titulo {
    font-size: 44px;
    line-height: 48px;
  }

  .titulo > span {
    font-size: 44px;
    line-height: 48px;
  }

  .subTitulo {
    font-size: 18px;
    line-height: 24px;
    color: #595959;
    margin-bottom: 8px;
  }

  .link {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 28px;
  }

  .sub {
    width: 246px;
  }
}
