.divTitulo {
  padding: 60px 120px;
  max-width: 1400px;
  background: #f7f7f7;
  margin: auto;
}

.divTituloProd {
  padding: 80px 120px;
  max-width: 1400px;
  background: #f7f7f7;
  margin: auto;
}

.divH1Wpp {
  display: flex;
  flex-direction: row;
}

.divH1Wpp img{
  margin-right: 12px;
}

.subTitulo {
  font-family: var(--type-second);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: #707070;
}

.titulo {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #121212;
}

.titulo span {
  color: #3db191;
}

.divH1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -34px;
  justify-content: center;
}

.divH1 img {
  margin-right: -75px;
}

@media (max-width: 600px) {
  .divTitulo {
    padding: 40px 20px;
    max-width: 100vw;
  }

  .subTitulo {
    font-size: 14px;
    line-height: 24px;
  }

  .titulo {
    font-size: 32px;
    line-height: 36px;
  }

  .titulo span {
    color: #3db191;
  }

  .divH1 {
    margin-left: -12px;
    margin-right: -20px;
  }

  .divH1 img {
    margin-right: -46px;
    width: 68px;
  }

  .divTituloProd {
    padding: 60px 20px 40px;
  }

  .divH1Wpp img{
    margin-right: 12px;
    height: 36px;
    width: 36px;
  }

}
