.titulo {
  font-family: var(--type-first);
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #121212;
  margin-bottom: 32px;
}

.container {
  max-width: 1400px;
  padding: 120px;
  margin: auto;
}

.ponto {
  color: #41bd9a;
}
