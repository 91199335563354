.container {
  max-width: 1400px;
  margin: auto;
}

.container > :nth-child(even) {
  display: flex;
  padding-top: 60px;
  background: linear-gradient(to right, #ededed 980px, white 420px);
  flex-direction: row-reverse;
}

@media (max-width: 600px) {
  .container {
    max-width: 100vw;
  }

  .container > :nth-child(even) {
    display: flex;
    padding-top: 60px;
    background: #ededed;
    flex-direction: column;
  }
}
